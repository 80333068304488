import React, { useContext } from 'react';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import { LinearProgress } from '@material-ui/core';
import { NavBar, Wrapper } from '@eisc-frontend/shared-ui';
import { navigate } from 'gatsby';
import { isLoggedIn, logout, isStaffUser } from '../contexts/auth';
import { OrsRoutes } from '../routes';
import { GlobalContext } from './../contexts/GlobalContext';

const Page = (props: any) => {
  const { children, isFetching, container, isBusy, isStaticHome, wrapperBody } = props;
  const content = isFetching ? <LinearProgress /> : children;
  const isStaff = isStaffUser();
  const isLogged = isLoggedIn();

  const globalContext = useContext(GlobalContext);

  function getInitials() {
    if (!isLogged) {
      return '';
    }
    const profile = Cookies.getJSON('ORS').profile;
    return `
      ${profile.firstName?.charAt(0).toUpperCase()}
      ${profile.lastName?.charAt(0).toUpperCase()}`;
  }

  const navBar: React.ReactElement = (
    <NavBar
      isStaticHome={isStaticHome}
      authActionSubmit={() => {
        if (isLogged) {
          logout();
        } else {
          navigate(OrsRoutes.OrsBase);
        }
      }}
      links={[
        {
          title: 'Home',
          value: '/',
          onClick: () => navigate('/'),
        },
        {
          title: 'User Manual',
          value: 'https://eiscors.blob.core.windows.net/public/manual/EISC-user-manual.pdf',
          visible: isStaff,
          target: '_blank',
        },
        {
          title: 'Project Submissions',
          value: '/submissions',
          onClick: () => {
            window.location.href = '/submissions';
          },
        },
        {
          title: 'System Management',
          value: OrsRoutes.SystemManagement,
          visible: isStaff,
        },
      ]}
      loggedIn={isLogged}
      userInitials={getInitials()}
      onUserInitialClick={() => navigate(`${process.env.GATSBY_ORS_BASE_URL}/edit-user-profile`)}
    />
  );

  return (
    <Wrapper
      isFetching={isFetching}
      isBusy={isBusy}
      navBar={navBar}
      container={container}
      message={globalContext.message}
      classes={{ wrapperBody }}
    >
      {content}
    </Wrapper>
  );
};

Page.defaultProps = {
  isFetching: false,
  isBusy: false,
};

Page.propTypes = {
  children: PropTypes.any.isRequired,
  isFetching: PropTypes.bool.isRequired,
  container: PropTypes.bool,
  isStaticHome: PropTypes.bool,
  wrapperBody: PropTypes.string,
};

export default Page;
