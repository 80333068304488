"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StatusWithNotification = exports.AllowedStatusTransitions = exports.ActiveStatusTypes = exports.StaffViewStatusTypes = exports.ReviewStatusEnum = exports.YesNoOptions = exports.PhaseOptions = exports.WaterBodiesEnum = exports.LandTypeEnum = exports.JurisdictionEnum = exports.CatalogCodeEnum = exports.ConservationPlanCategoryEnum = exports.InformationRequestItemLabels = exports.ReviewItemLabels = exports.CommentType = exports.UserType = exports.UserMode = exports.UserSubmissionType = exports.SubmissionType = exports.DEFAULT_MAP_CENTRE = exports.AUTOCOMPLETE_DEFAULT_PAGE_SIZE = exports.SORT_ORDER = exports.SIZE_CONVERTER = exports.CommentOrigin = void 0;
var CommentOrigin;
(function (CommentOrigin) {
    CommentOrigin["Online"] = "Online";
    CommentOrigin["Import"] = "Import";
})(CommentOrigin = exports.CommentOrigin || (exports.CommentOrigin = {}));
exports.SIZE_CONVERTER = ['B', 'KB', 'MB', 'GB'];
exports.SORT_ORDER = ['DESC', 'ASC'];
exports.AUTOCOMPLETE_DEFAULT_PAGE_SIZE = 500;
exports.DEFAULT_MAP_CENTRE = {
    lat: 48.45472579306553,
    lng: -123.33783040160064,
};
var SubmissionType;
(function (SubmissionType) {
    SubmissionType["Add"] = "ADD";
    SubmissionType["Update"] = "UPDATE";
    SubmissionType["Delete"] = "DELETE";
})(SubmissionType = exports.SubmissionType || (exports.SubmissionType = {}));
var UserSubmissionType;
(function (UserSubmissionType) {
    UserSubmissionType[UserSubmissionType["UpdateOwnStaff"] = 0] = "UpdateOwnStaff";
    UserSubmissionType[UserSubmissionType["UpdateOwnNonStaff"] = 1] = "UpdateOwnNonStaff";
    UserSubmissionType[UserSubmissionType["UpdateByStaff"] = 2] = "UpdateByStaff";
    UserSubmissionType[UserSubmissionType["AddByStaff"] = 3] = "AddByStaff";
    UserSubmissionType[UserSubmissionType["Register"] = 4] = "Register";
    UserSubmissionType[UserSubmissionType["Delete"] = 5] = "Delete";
})(UserSubmissionType = exports.UserSubmissionType || (exports.UserSubmissionType = {}));
var UserMode;
(function (UserMode) {
    UserMode[UserMode["UpdateOwnStaff"] = 0] = "UpdateOwnStaff";
    UserMode[UserMode["UpdateOwnNonStaff"] = 1] = "UpdateOwnNonStaff";
    UserMode[UserMode["UpdateByStaff"] = 2] = "UpdateByStaff";
    UserMode[UserMode["AddByStaff"] = 3] = "AddByStaff";
    UserMode[UserMode["Registration"] = 4] = "Registration";
})(UserMode = exports.UserMode || (exports.UserMode = {}));
var UserType;
(function (UserType) {
    UserType["Staff"] = "Staff";
    UserType["User"] = "User";
})(UserType = exports.UserType || (exports.UserType = {}));
var CommentType;
(function (CommentType) {
    CommentType["Comment"] = "comment";
    CommentType["InformationRequest"] = "informationRequest";
})(CommentType = exports.CommentType || (exports.CommentType = {}));
exports.ReviewItemLabels = {
    sendNotification: 'Send Notification',
    breadcrumbs: 'Submission',
    boardPrefix: 'Project Submitted to:',
    editItem: 'Edit Submission',
    print: 'Print this Submission',
    fileNumber: 'File Number',
    documentsPrefix: 'This submission has',
    noDocuments: 'This project has no attached documents',
    submitCommentsOnlineTitle: 'Ready to add your voice?',
    submitCommentsOnline: 'Submit Your Comments Online',
    submitCommentsOnlineBox: 'Use our online comment editor to submit your comments online. You can save your progress and submit when ready.',
    addCommentsOnlineText: 'Add Comments Online',
    uploadCommentsButton: 'Upload Comment Excel File',
    noAttachments: 'This project has no attachments',
    attachmentsListPrefix: 'The submission contains ',
    infoBox1: 'This project is currently accepting comments.',
    infoBox2: 'The comment period will close in',
    submitText: 'Submit',
    comment: 'Comment',
    addResponseOnlineText: 'Add Response Online',
    recommendation: 'Recommendation',
    addCommentPlaceholder: 'Add your comment',
    addRecommendationPlaceholder: 'Add your recommendation',
    editCommentsOnBehalf: 'Editing comments on behalf of',
    submitResponsesOnline: 'Submit your Responses online',
    submitResponsesOnlineBox: 'Use our online tool to submit your responses.',
    addAnotherRow: 'Add Another Comment Row',
    itemNoComments: 'This submission has no comments',
    informationRequest: 'Information Request',
    submitInformationRequestOnlineBox: 'Use our online tool to submit Information Request or Respond to Information Request online. You can save your progress and submit when ready.',
    addInformationRequestOnlineText: 'Add Information Request Online',
};
exports.InformationRequestItemLabels = {
    sendNotification: 'Send Notification',
    breadcrumbs: 'Information Request',
    boardPrefix: 'Information Request Phase for ',
    editItem: 'Edit Information Request',
    completeItem: 'Mark Information Request As Completed',
    print: 'Print Information Request',
    fileNumber: 'File/EA Number',
    documentsPrefix: 'This Information Request has',
    noDocuments: 'This Information Request has no documents',
    submitCommentsOnlineTitle: 'Ready to add your voice?',
    submitCommentsOnline: 'Submit Your Comments Online',
    informationRequest: 'Information Request',
    submitCommentsOnlineBox: 'Use our online comment editor to submit your comments online. You can save your progress and submit when ready.',
    submitInformationRequestOnlineBox: 'Use our online tool to submit Information Request or Respond to Information Request online. You can save your progress and submit when ready.',
    addCommentsOnlineText: 'Add Comments Online',
    addResponseOnlineText: 'Add Response Online',
    addInformationRequestOnlineText: 'Add Information Request Online',
    uploadCommentsButton: 'Upload Completed Excel File',
    noAttachments: 'This Information Request has no attachments',
    attachmentsListPrefix: 'This Information Request contains ',
    infoBox1: 'This submission is currently in an information request phase.',
    infoBox2: 'The Information Request period will close in',
    submitText: 'Submit',
    comment: 'Preamble',
    recommendation: 'Request',
    addCommentPlaceholder: 'Add your preamble',
    addRecommendationPlaceholder: 'Add your request',
    editCommentsOnBehalf: 'Editing comments on behalf of',
    submitResponsesOnline: 'Submit your Responses online',
    submitResponsesOnlineBox: 'Use our online tool to submit your responses.',
    addAnotherRow: 'Add Another Row',
    itemNoComments: 'This Information Request has no comments',
};
var ConservationPlanCategoryEnum;
(function (ConservationPlanCategoryEnum) {
    ConservationPlanCategoryEnum["CategoryA"] = "Category A";
    ConservationPlanCategoryEnum["CategoryB"] = "Category B";
    ConservationPlanCategoryEnum["CategoryC"] = "Category C";
    ConservationPlanCategoryEnum["CategoryD"] = "Category D";
    ConservationPlanCategoryEnum["CategoryE"] = "Category E";
})(ConservationPlanCategoryEnum = exports.ConservationPlanCategoryEnum || (exports.ConservationPlanCategoryEnum = {}));
var CatalogCodeEnum;
(function (CatalogCodeEnum) {
    CatalogCodeEnum["Country"] = "COUNTRY";
    CatalogCodeEnum["TypeDevelopment"] = "TYPE_DEVELOPMENT";
    CatalogCodeEnum["Regulator"] = "REGULATOR";
    CatalogCodeEnum["MitigationMeasures"] = "MITIGATION_MEASURES";
})(CatalogCodeEnum = exports.CatalogCodeEnum || (exports.CatalogCodeEnum = {}));
var JurisdictionEnum;
(function (JurisdictionEnum) {
    JurisdictionEnum["GwichinSettlementArea"] = "Gwich`in Settlement Area";
    JurisdictionEnum["NunavutSettlementArea"] = "Nunavut Settlement Area";
    JurisdictionEnum["SahtuSettlementArea"] = "Sahtu Settlement Area";
})(JurisdictionEnum = exports.JurisdictionEnum || (exports.JurisdictionEnum = {}));
var LandTypeEnum;
(function (LandTypeEnum) {
    LandTypeEnum["CrownLands"] = "Crown Lands";
    LandTypeEnum["InuvialuitPrivateLands"] = "Inuvialuit Private Lands";
    LandTypeEnum["OffshoreMarineEnvironment"] = "Offshore/Marine Environment";
    LandTypeEnum["TerritorialLands"] = "Territorial Lands";
})(LandTypeEnum = exports.LandTypeEnum || (exports.LandTypeEnum = {}));
var WaterBodiesEnum;
(function (WaterBodiesEnum) {
    WaterBodiesEnum["WorkNearWaterBodies"] = "Work near water bodies";
    WaterBodiesEnum["WorkInWaterBodies"] = "Work in water bodies";
    WaterBodiesEnum["WorkOnWaterBodies"] = "Work on water bodies";
    WaterBodiesEnum["CrossingOfWaterBodies"] = "Crossing of water bodies (streams/rivers)";
    WaterBodiesEnum["WaterUsage"] = "Water usage";
    WaterBodiesEnum["DepositingOfAnythingInAWaterBody"] = "Depositing of anything in a water body";
})(WaterBodiesEnum = exports.WaterBodiesEnum || (exports.WaterBodiesEnum = {}));
exports.PhaseOptions = [
    {
        value: 2,
        label: 'Please proceed directly to phase 2 of the environmental impact screening process. A completed Project Description as per the EISC Guidelines must also be included.',
    },
    {
        value: 1,
        label: 'Please review the proposed project to determine whether it is subject to the environmental screening process, and/or whether it is exempt from screening.',
    },
];
exports.YesNoOptions = [
    {
        value: true,
        label: 'Yes',
    },
    {
        value: false,
        label: 'No',
    },
];
var ReviewStatusEnum;
(function (ReviewStatusEnum) {
    ReviewStatusEnum["Draft"] = "Draft";
    ReviewStatusEnum["Submitted"] = "Submitted";
    ReviewStatusEnum["PreScreening"] = "Pre-Screening";
    ReviewStatusEnum["AwaitingProjectDescription"] = "Awaiting Project Description";
    ReviewStatusEnum["DescriptionComplete"] = "Description Complete";
    ReviewStatusEnum["AwaitingComments"] = "Awaiting Comments";
    ReviewStatusEnum["ClosedForComments"] = "Closed For Comments";
    ReviewStatusEnum["Closed"] = "Closed";
})(ReviewStatusEnum = exports.ReviewStatusEnum || (exports.ReviewStatusEnum = {}));
exports.StaffViewStatusTypes = [
    ReviewStatusEnum.Draft,
    ReviewStatusEnum.Submitted,
    ReviewStatusEnum.PreScreening,
    ReviewStatusEnum.AwaitingProjectDescription,
    ReviewStatusEnum.DescriptionComplete,
    ReviewStatusEnum.AwaitingComments,
    ReviewStatusEnum.ClosedForComments,
    ReviewStatusEnum.Closed,
];
exports.ActiveStatusTypes = [
    ReviewStatusEnum.Submitted,
    ReviewStatusEnum.PreScreening,
    ReviewStatusEnum.AwaitingProjectDescription,
    ReviewStatusEnum.DescriptionComplete,
    ReviewStatusEnum.AwaitingComments,
    ReviewStatusEnum.ClosedForComments,
    ReviewStatusEnum.Closed,
];
exports.AllowedStatusTransitions = {
    [ReviewStatusEnum.Draft]: [],
    [ReviewStatusEnum.Submitted]: [
        { value: ReviewStatusEnum.PreScreening, label: ReviewStatusEnum.PreScreening },
        { value: ReviewStatusEnum.Closed, label: ReviewStatusEnum.Closed },
    ],
    [ReviewStatusEnum.PreScreening]: [
        {
            value: ReviewStatusEnum.AwaitingProjectDescription,
            label: ReviewStatusEnum.AwaitingProjectDescription,
        },
        { value: ReviewStatusEnum.Closed, label: ReviewStatusEnum.Closed },
    ],
    [ReviewStatusEnum.AwaitingProjectDescription]: [
        {
            value: ReviewStatusEnum.DescriptionComplete,
            label: ReviewStatusEnum.DescriptionComplete,
        },
        { value: ReviewStatusEnum.Closed, label: ReviewStatusEnum.Closed },
    ],
    [ReviewStatusEnum.DescriptionComplete]: [
        {
            value: ReviewStatusEnum.AwaitingComments,
            label: ReviewStatusEnum.AwaitingComments,
        },
    ],
    [ReviewStatusEnum.AwaitingComments]: [
        {
            value: ReviewStatusEnum.ClosedForComments,
            label: ReviewStatusEnum.ClosedForComments,
        },
    ],
    [ReviewStatusEnum.ClosedForComments]: [
        {
            value: ReviewStatusEnum.AwaitingComments,
            label: ReviewStatusEnum.AwaitingComments,
        },
        { value: ReviewStatusEnum.Closed, label: 'Completed' },
    ],
    [ReviewStatusEnum.Closed]: [],
};
exports.StatusWithNotification = [
    ReviewStatusEnum.AwaitingProjectDescription,
    ReviewStatusEnum.DescriptionComplete,
    ReviewStatusEnum.Closed,
];
