/**
 * Functions for determining logged in state
 * Uses cookies. Running locally, this would not work. But if the apps are deployed and on the same domain, it should
 */

import Cookies from 'js-cookie';
import { UserType } from '@eisc-frontend/shared-ui';
import { navigate } from 'gatsby';

const IDLE_TIMEOUT = parseInt(process.env.GATSBY_IDLE_TIMEOUT || '3600000', 10);

let idleTimeout;

export const getUser = () => {
  return Cookies.getJSON('ORS')?.profile || null;
};

export const isLoggedIn = () => {
  if (Cookies.getJSON('ORS') && Cookies.getJSON('ORS').profile) {
    return true;
  }

  return false;
};

export const getUserType = () => {
  if (!Cookies.getJSON('ORS')?.profile) {
    return null;
  }
  return Cookies.getJSON('ORS').profile.userType;
};

export const getUserId = () => {
  if (!Cookies.getJSON('ORS')?.profile) return null;
  return Cookies.getJSON('ORS').profile.id;
};

export const logout = async () => {
  Cookies.remove('ORS');
  navigate(process.env.GATSBY_ORS_BASE_URL);
};

export const isStaffUser = () => {
  return Cookies.getJSON('ORS')
    ? Cookies.getJSON('ORS').profile?.userType === UserType.Staff
    : false;
};

export const isProponentUser = (proponentUsers) => {
  if (!isLoggedIn) {
    return false;
  }
  const userId = Cookies.getJSON('ORS')?.profile?.id;
  return proponentUsers?.some((user) => user.id === userId);
};

export const restartIdle = () => {
  if (idleTimeout) {
    clearTimeout(idleTimeout);
  }

  if (isLoggedIn()) {
    idleTimeout = setTimeout(() => {
      logout();
    }, IDLE_TIMEOUT);
  }
};

export const setUserProfile = (accessToken, refreshToken, profile, refreshExpiry) => {
  Cookies.set(
    'ORS',
    {
      accessToken,
      refreshToken,
      profile,
    },
    {
      expires: new Date(refreshExpiry * 1000),
    },
  );

  restartIdle();
};

export const isStakeholderUser = (users) => {
  if (!isLoggedIn) {
    return false;
  }
  const userId = Cookies.getJSON('ORS')?.profile?.id;
  return users?.some((user) => user.id === userId);
};
